@import '../../../../scss/theme-bootstrap';

.product-add-favorites {
  display: block;
  float: left;
  font-size: 20px;
  margin: 0.5em;
  @include breakpoint($large-up) {
    display: inline-block;
    float: none;
    margin: 0;
  }
  &:active,
  &:link,
  &:hover,
  &:visited {
    outline: none;
    text-decoration: none;
  }
}

.tooltipster-smashbox {
  background: $color-black;
  color: $color-white;
  max-width: 300px;
  a {
    color: $color-white;
    text-decoration: underline;
  }
  .tooltipster-content {
    @include font--text;
    font-size: 14px;
    line-height: normal;
    overflow: hidden;
    padding: 15px;
    text-transform: uppercase;
  }
  .anon-message {
    display: block;
    text-transform: none;
    body.signed-in & {
      display: none;
    }
  }
}
